<template>
  <main class="camera">
    <div class="videocontainer" v-if="camera_available">
      <div></div>
      <video v-show="!imgshow" ref="streamel" autoplay playsinline></video>
      <img ref="imgel" v-show="imgshow" src="" />
      <canvas style="display: none" ref="canvasel"></canvas>
    </div>
    <div class="videocontainer videocontainer-100" v-else>
      <div></div>
      <img ref="imgel" class="bgimg" src="" v-show="imgshow" />
      <input
        style="display: none"
        class="bigbutton orange"
        type="file"
        capture="environment"
        accept="image/*"
        ref="fileinput"
        @change="selectedpic"
      />
    </div>
    <teleport to="#buttongroupfooter" v-if="camera_available">
      <button
        @click="imgshow = false"
        class="cancelbtn"
        v-if="imgshow"
      >
      </button>
      <button @click="takescreenshot" v-if="!imgshow" class="fotobutton"></button>
      <button @click="resume" v-if="imgshow" class="bigbutton orange continuebtn">
        Weiter
      </button>
    </teleport>
    <teleport to="#buttongroupfooter" v-else>
      <button @click="selectpic" class="bigbutton orange">
        Foto aufnehmen
      </button>
      <button
        class="bigbutton orange"
        style="margin-left: 0.2em;"
        v-if="selectedfile"
        @click="resume_withoutcam"
      >
        Weiter
      </button>
    </teleport>
  </main>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "camera",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const imgshow = ref(false);
    const camera_available = ref(true);
    const selectedfile = ref(false);
    const fileinput = ref(null);

    const streamel = ref(null);
    const canvasel = ref(null);
    const imgel = ref(null);
    const constraints = {
      audio: false,
      video: {
        aspectRatio: 1,
        facingMode: "environment",
      },
    };
    startcam();
    async function startcam() {
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        try {
          let stream = await navigator.mediaDevices.getUserMedia(constraints);
          let track = stream.getVideoTracks()[0];
          track.onended = (e) => (camera_available.value = false);
          streamel.value.srcObject = stream;
        } catch (e) {
          camera_available.value = false;
        }
      } else {
        camera_available.value = false;
      }
    }
    function takescreenshot() {
      canvasel.value.width = streamel.value.videoWidth;
      canvasel.value.height = streamel.value.videoHeight;
      canvasel.value.getContext("2d").drawImage(streamel.value, 0, 0);
      imgel.value.src = canvasel.value.toDataURL("image/jpg");
      imgshow.value = true;
    }
    function resume() {
      const stream = streamel.value.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach(function (track) {
        track.stop();
      });

      streamel.value.srcObject = null;
      canvasel.value.toBlob((blob) => {
        store.commit("setpicture", blob);
      }, "image/jpg");
      router.push("upload");
    }

    function selectpic() {
      fileinput.value.click();
    }
    function selectedpic(event) {
      imgel.value.src = URL.createObjectURL(event.target.files[0]);
      store.commit("setpicture", event.target.files[0]);
      imgshow.value = true;
      selectedfile.value = true
    }
    function resume_withoutcam() {
      router.push("upload");
    }

    return {
      camera_available,
      selectedfile,
      streamel,
      canvasel,
      imgel,
      imgshow,
      takescreenshot,
      resume,
      selectpic,
      selectedpic,
      fileinput,
      resume_withoutcam
    };
  },
};
</script>
